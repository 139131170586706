<template>
  <div>
    <Nav />
    <Notification />
    <v-main>
      <v-container
        grid-list-xl
        fluid
      >
        <breadcrumbs />
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Breadcrumbs from '@/components/Breadcrumbs'
import Notification from '@/components/Notification'

export default {
  name: 'BasicLayout',
  components: {
    Nav,
    Breadcrumbs,
    Notification
  }
}
</script>
