<template>
  <div>
    <v-navigation-drawer
      id="appDrawer"
      v-model="drawer"
      fixed
      app
      :mini-variant="miniVariant"
      :clipped="clipped"
      width="230"
    >
      <v-app-bar
        color="primary darken-1"
        dark
      >
        <img
          src="@/assets/quiz/small_logo.png"
          height="35"
        >
        <v-toolbar-title class="ml-0 pl-3">
          <span class="hidden-sm-and-down">{{ siteName }}</span>
        </v-toolbar-title>
      </v-app-bar>
      <v-list
        dense
        expand
      >
        <template v-for="link in navLinks(links)">
          <!--top-level link-->
          <v-list-item
            v-if="checkPermission(link.links)"
            :key="link.name"
            :to="link.route ? { name: link.route } : null"
          >
            <v-list-item-action v-if="link.icon">
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="link.name" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
    >
      <v-app-bar-nav-icon
        data-cy="drawer"
        @click="drawer = !drawer"
      />
      <v-btn
        icon
        class="hidden-sm-and-down"
        @click.stop="miniVariant = !miniVariant"
      >
        <v-icon>{{ `mdi-chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
      </v-btn>
      <v-btn
        icon
        class="hidden-sm-and-down"
        @click.stop="clipped = !clipped"
      >
        <v-icon>mdi-arrow-expand-vertical</v-icon>
      </v-btn><v-spacer />
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            icon
            @click="logout"
          >
            <v-icon v-on="on">
              mdi-exit-to-app
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('auth.logout') }}</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>
import authHeader, { logout } from '@/utils'

export default {
  name: 'Nav',
  data () {
    return {
      miniVariant: false,
      clipped: false,
      drawer: true,
      scrollSettings: {
        maxScrollbarLength: 160
      },
      siteName: process.env.VUE_APP_NAME,
      links: [
        { route: 'dashboard.home', name: this.$t('messages.home'), icon: 'mdi-view-dashboard' },
        { route: 'dashboard.user', name: this.$t('messages.user'), icon: 'mdi-contacts', permission: 'user.view' },
        { route: 'dashboard.role', name: this.$t('messages.role'), icon: 'mdi-eye', permission: 'role.view' },
        { route: 'dashboard.permission', name: this.$t('messages.permission'), icon: 'mdi-lock', permission: 'permission.view' },
        { route: 'dashboard.quiz', name: this.$t('messages.quiz'), icon: 'mdi-chat-question', permission: 'quiz.view' },
        { route: 'dashboard.question', name: this.$t('messages.question'), icon: 'mdi-account-question', permission: 'question.view' },
        { route: 'dashboard.answer', name: this.$t('messages.answer'), icon: 'mdi-forum', permission: 'answer.view' },
        { route: 'dashboard.result', name: this.$t('messages.result'), icon: 'mdi-note-text', permission: 'result.view' }
      ]
    }
  },
  methods: {
    checkPermission (links) {
      if (Array.isArray(links)) {
        const allowed = links.filter(v => {
          return this.$can(v.permission)
        })
        return allowed.length > 0
      }
      return true
    },
    navLinks (arr) {
      return arr.filter(link => {
        if (link.permission) {
          return this.$can(link.permission)
        }
        return true
      })
    },
    logout () {
      this.$http.post('/logout', { headers: authHeader() })
        .then(() => {
          logout()
          this.$store.commit('setNotification', { color: 'success', message: 'Sucessfully logout' })
          this.$router.push({ name: 'home' })
        }).catch(error => {
          this.displayError(error)
        })
    }
  }
}
</script>
