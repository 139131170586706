<template>
  <v-flex
    v-if="$route.name !== 'dashboard.home'"
    lg12
  >
    <nav
      ref="breadcrumb"
      aria-label="breadcrumb"
    >
      <app-breadcrumbs
        container="ul"
        class="v-breadcrumbs py-2"
      >
        <li
          v-if="to.path !== '/dashboard'"
          slot-scope="{to, label}"
          class="breadcrumb-item pl-0"
        >
          <router-link
            :to="to"
            class="v-breadcrumbs__item pr-2"
            exact
          >
            <v-icon
              v-if="to.name === 'dashboard.home'"
              class="pr-1 pb-1"
            >
              mdi-home
            </v-icon>
            {{ label }}
          </router-link>
        </li>
        <span
          slot="current"
          slot-scope="{label}"
          class="custom-current-class"
        >
          {{ label }}
        </span>
      </app-breadcrumbs>
    </nav>
  </v-flex>
</template>

<script>
export default {
  name: 'Breadcrumbs'
}
</script>

<style lang="sass">
  @import '~vuetify/src/components/VBreadcrumbs/VBreadcrumbs'

  .current-breadcrumb
    color: #6c757d

  .parent-breadcrumb
    display: none

  .breadcrumb-item::after
    display: inline-block
    color: #6c757d
    content: "/"
</style>
